<template>
  <div v-animate-css="{
        classes: 'fadeIn',
        duration: 1000,
      }">
    <div class="text-center mb-5">
      <h4>{{ question.order }}. {{ question.title }}</h4>
      <p>{{ question.description }}</p>
    </div>
    <div class="d-flex justify-content-center my-5">
      <div class="icon-card" @click="result = false" :class="{'selected-false': result === false}"><img
        src="../../assets/icons/icono_no.svg" alt="">
        <p class="m-0">No</p></div>
      <div class="icon-card" @click="result = true" :class="{'selected-true': result === true}"><img
        src="../../assets/icons/icono_si.svg" alt="">
        <p class="m-0">Si</p></div>
    </div>
    <div class=" buttons row mt-5 ">
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" @click="handlePrev">
          {{ $store.getters.getPrevText }}
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" :disabled="handleValidation" @click="handleNext">
          {{$store.getters.getNextText}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {yesNotInitialData} from "../../utils/questionsInitialData";
import store from "../../store";
import Vue from 'vue';
import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);

export default {
  name: "SwitchHand",
  components: {},
  props: {},
  data() {
    return {
      question: yesNotInitialData,
      result: null,
    }
  },
  mounted() {
    this.result = this.question.result.answer
  },
  computed: {
    handleValidation() {
      return this.result === null
    }
  },
  watch: {
    '$store.state.currentQuestion': function () {
      this.question = store.state.currentQuestion;
      if (store.state.currentQuestion.result.answer !== null) {
        this.result = store.state.currentQuestion.result.answer
      }
    }
  },
  methods: {
    async handleNext() {
      const data = {
        idQuestion: store.getters.getCurrentQuestion._id,
        orderQuestion: store.getters.getCurrentQuestion.order,
        section: 'valorization',
        idUser: JSON.parse(localStorage.userData)._id,
        email: JSON.parse(localStorage.userData).email,
        answer: this.result
      }
      this.$emit('sendQuestion', data);
    },
    handlePrev() {
      this.$emit('goPrev');
    },
    getQuestionData() {
      if (store.state.currentQuestion.type === 'multipleWithImages') {
        this.question = store.state.currentQuestion;
      }
    },
  },

}
</script>

<style scoped lang="scss">
.icon {
  fill: #23AF4D;
}

.controls-button {
  &:hover {
    .icon   {
      fill: white ;
    }
  }
}

.buttons {
  button {
    margin: 0 0.5rem;

    &:disabled {
      filter: grayscale(100%);
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.selected-true {
  background-color: rgba(35, 175, 77, 0.1);
  border-color: #23af4d !important;
}

.selected-false {
  background-color: rgba(255, 0, 0, 0.1);
  border-color: red !important;
}

.icon-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 15px;
  padding: 1rem 2rem;
  margin: 0 1rem;

  &:hover {
    cursor: pointer;
    transition: background-color 200ms ease;
  }
}
</style>
